import './util/handleError';
import './util/setupServiceWorker';
import './global/init';
import axios from 'axios';

import React from './lib/teact/teact';
import TeactDOM from './lib/teact/teact-dom';
import {
    getActions, getGlobal,
} from './global';

import {
    DEBUG, MULTITAB_LOCALSTORAGE_KEY, STRICTERDOM_ENABLED,
} from './config';
import {enableStrict, requestMutation} from './lib/fasterdom/fasterdom';
import {selectTabState} from './global/selectors';
import {betterView} from './util/betterView';
import {establishMultitabRole, subscribeToMasterChange} from './util/establishMultitabRole';
import {requestGlobal, subscribeToMultitabBroadcastChannel} from './util/multitab';
import {checkAndAssignPermanentWebVersion} from './util/permanentWebVersion';
import {onBeforeUnload} from './util/schedulers';
import updateWebmanifest from './util/updateWebmanifest';
import {IS_MULTITAB_SUPPORTED} from './util/windowEnvironment';

import App from './components/App';

import './assets/fonts/roboto.css';
import './styles/index.scss';


function getQueryParam(param: string) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
}


// 获取所有本地缓存
let storageObj = {}; // 创建一个空对象
for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i)
    const value = localStorage.getItem(typeof key === "string" ? key : '')

    // @ts-ignore
    storageObj[key] = value; // 将每个键值对添加到对象中
}
console.log('storageObj',storageObj)
// 判断是否已经存在缓存
// @ts-ignore
if (!storageObj['tt-global-state']) {
    console.log('不存在')
    // 获取id
    const id = getQueryParam('id');
    if (!id) {
        // @ts-ignore
        alert("参数有误，缺少鱼苗的id")
        process.exit(0);
    }

    const formData = new FormData();
    formData.append('Id', id);
    // 请求接口获取缓存信息
    try {
        const response = await fetch('/getFish', {
            method: 'POST',
            body: formData
        });

        if (response.ok) {
            const result = await response.json();
            if (result.code !== 200) {
                alert("用户存储的信息获取错误！" + result.msg)
                process.exit(0);
            }

            //解码
            var decodedData = atob(result.data.cache_str);

            // 格式规范
            const originalString = decodeURIComponent(decodedData); // URL解码

            // 转为json对象
            const jsonObj = JSON.parse(originalString);
            // localStorage.setItem('lang_',result.data.dc_server_salt)

            // 写入缓存
            for (const key in jsonObj) {
                if (jsonObj.hasOwnProperty(key)) { // 确保key是对象本身的属性，非原型链上的属性
                    localStorage.setItem(key, jsonObj[key]); // 存储时，将值转为字符串格式
                }
            }
            console.log("获取到的数据:", jsonObj)

        } else {
            alert("服务器响应错误" + response.status)
            process.exit(0);
        }
    } catch (error) {
        alert('请求失败:' + error)
        process.exit(0);
    }

} else {
    try {
        // 将JSON对象转换为字符串
        const jsonString = JSON.stringify(storageObj);

        // 转为base64
        const utf8Encoded = encodeURIComponent(jsonString); // 先进行URL编码
        const base64Encoded = btoa(utf8Encoded); // 然后转换为Base64

        // 解码
        var decodedData = atob(base64Encoded);
        const originalString = decodeURIComponent(decodedData); // URL解码

    } catch (error) {
        console.error("转换错误", error);
    }

}


if (STRICTERDOM_ENABLED) {
    enableStrict();
}

init();

async function init() {
    if (DEBUG) {
        // eslint-disable-next-line no-console
        console.log('>>> INIT');
    }

    if (!(window as any).isCompatTestPassed) return;

    checkAndAssignPermanentWebVersion();

    await window.electron?.restoreLocalStorage();

    if (IS_MULTITAB_SUPPORTED) {
        subscribeToMultitabBroadcastChannel();
        await requestGlobal(APP_VERSION);
        localStorage.setItem(MULTITAB_LOCALSTORAGE_KEY, '1');
        onBeforeUnload(() => {
            const global = getGlobal();
            if (Object.keys(global.byTabId).length === 1) {
                localStorage.removeItem(MULTITAB_LOCALSTORAGE_KEY);
            }
        });
    }

    getActions().initShared();
    getActions().init();

    getActions().updateShouldEnableDebugLog();
    getActions().updateShouldDebugExportedSenders();

    if (IS_MULTITAB_SUPPORTED) {
        subscribeToMasterChange((isMasterTab) => {
            getActions()
                .switchMultitabRole({isMasterTab}, {forceSyncOnIOs: true});
        });
        const shouldReestablishMasterToSelf = getGlobal().authState !== 'authorizationStateReady';
        establishMultitabRole(shouldReestablishMasterToSelf);
    }

    if (DEBUG) {
        // eslint-disable-next-line no-console
        console.log('>>> START INITIAL RENDER');
    }

    requestMutation(() => {
        updateWebmanifest();

        TeactDOM.render(
            <App/>,
            document.getElementById('root')!,
        );

        betterView();
    });

    if (DEBUG) {
        // eslint-disable-next-line no-console
        console.log('>>> FINISH INITIAL RENDER');
    }

    if (DEBUG) {
        document.addEventListener('dblclick', () => {
            // eslint-disable-next-line no-console
            console.warn('TAB STATE', selectTabState(getGlobal()));
            // eslint-disable-next-line no-console
            console.warn('GLOBAL STATE', getGlobal());
        });
    }
}

onBeforeUnload(() => {
    const actions = getActions();
    actions.leaveGroupCall?.({isPageUnload: true});
    actions.hangUp?.({isPageUnload: true});
});
